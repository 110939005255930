import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Title } from "../title";

import "./image.scss";

const ImageUnavailable = (props) => {
    const containerClass = props && props.containerClass ? `g-flex__container g-flex__container--column-always g-flex__container--justify-center g-flex__container--align-center ${props.containerClass}` : "g-flex__container g-flex__container--column g-flex__container--justify-center g-flex__container--align-center";

    return (
        <div className={containerClass}>
            <Title containerClassName="c-image__logo g-flex__item" />
            <p className="g-flex__item">Image is currently unavailable.</p>
        </div>
    );
};

export const Image = props => {
    const image = getImage(props.image);

    if (image && props.constrained || image && props.thumbnail) {
        return (
            <div className={`c-image c-image--constrained ${props.className}`}>
                {props.gradientOverlay && <div className={`${props.gradientOverlay}`}></div>}
                <div className={`${props.imgWrapperClassName}`}>
                    <GatsbyImage layout="constrained" className={props.imgClassName} style={props.style} alt={props.alt} objectFit={props.objectFit || "contain"} objectPosition={props.objectPosition || "50% 50%"} image={image} />
                </div>
            </div>
        );
    }

    if (image) {
        return (
            <div className={`c-image c-image--full-width ${props.className}`}>
                {props.gradientOverlay && <div className={`${props.gradientOverlay}`}></div>}
                <div className={`${props.imgWrapperClassName}`}>
                    <GatsbyImage layout="fullWidth" className={props.imgClassName} style={props.style} alt={props.alt} objectFit={props.objectFit || "contain"} objectPosition={props.objectPosition || "50% 50%"} image={image} />
                </div>
            </div>
        );
    }

    const containerClass = props.thumbnail ? `c-image__fallback-thumbnail ${props.imgClassName}` : `c-image__fallback ${props.imgClassName}`;

    return (
        <div className={`c-image ${props.className}`}>
            <ImageUnavailable containerClass={containerClass} />
        </div>
    );

};
