import React from "react";

import { MDXRenderer } from "gatsby-plugin-mdx";

import { Image } from "../../components/image";
import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";


import "./article.scss";

const ArticleTemplate = ({ pageContext }) => {

    const pageDetails = {
        currentLanguageRegion: pageContext.currentLanguageRegion,
        title: pageContext.article.frontmatter.name,
        meta: pageContext.meta,
        script: pageContext.script,
        urlPath: pageContext.urlPath
    };

    return (
        <DefaultLayout pageDetails={pageDetails}>
            <ContentLayout affiliateDisclosure={true} title={pageContext.article.frontmatter.name}>
                <div className="p-article u-padding-top--2">
                    <div className="g-grid__container g-grid__container--2/3-1/3">
                        <div className="p-article__item g-grid__item g-grid__item--two">
                            <div className="p-article__image-container g-flex__container g-flex__container--column-always">
                                {pageContext.article && pageContext.article.imageFile2 && <Image
                                    imgClassName="p-article__secondary-image g-flex__item"
                                    image={pageContext.article.imageFile2}
                                    alt=""
                                />}
                            </div>
                        </div>
                        <div className="p-article__item g-grid__item g-grid__item--one g-flex__container">
                            {pageContext.article && pageContext.article.body && <MDXRenderer>{pageContext.article.body}</MDXRenderer>}
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </DefaultLayout>
    );
};

export default ArticleTemplate;
